// 综合监控
import {getRequest, postRequest, monitorGateway, urlTitle, gateway} from "@/utils/request";
import qs from "qs";

window.qs = qs;
// 储详情
export const storage = {
    //详情部分
    storageBasic: (params) => {
        return getRequest(`${urlTitle}/storage/detail/storageBasic/info`, params)
    },
    // storageBasic: (params) => {
    //     return getRequest(`/storage/detail/storageBasic/info`, params)
    // },

    storagePowerConsumption: (params) => {
        return getRequest(`${urlTitle}/storage/detail/storagePowerConsumption/info`, params)
    },

    // storagePowerConsumption: (params) => {
    //     return getRequest(`/storage/detail/storagePowerConsumption/info`, params)
    // },
    storageSystemDailyChargeCapacity: (params) => {
        return getRequest(`${urlTitle}/storage/detail/storageSystemDailyChargeCapacity/info`, params)
    },
    // storageSystemDailyChargeCapacity: (params) => {
    //     return getRequest(`/storage/detail/storageSystemDailyChargeCapacity/info`, params)
    // },
};