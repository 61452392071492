<template>
  <div id="storage">
    <myHeader></myHeader>
    <div class="backBox">
      <div class="titleLeft">
        <div class="back_nav" @click="goBack()"></div>
      </div>
    </div>
    <div class="bigBox">
      <div class="topBox">
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg29.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">{{ dayEarnings }}</div>
            <div class="value">{{ '日收益(元)' }}</div>
          </div>
        </div>
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg30.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">{{ dayChargeQty }}</div>
            <div class="value">{{ '当日充电量(MWh)' }}</div>
          </div>
        </div>
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg31.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">{{ dayDischargeQty }}</div>
            <div class="value">{{ '当日放电量(MWh)' }}</div>
          </div>
        </div>
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg32.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">{{ safeDays }}</div>
            <div class="value">{{ '安全运行天数(天)' }}</div>
          </div>
        </div>
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg33.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">{{ stationRunTime }}</div>
            <div class="value">{{ '电站运行时间(天)' }}</div>
          </div>
        </div>
        <div class="t_bgc">
          <div class="imgBox">
            <img src="../../assets/detail/simg34.png">
          </div>
          <div class="t_bgc_labelBox">
            <div class="label">
              <span style="font-size: 0.12rem;margin-right: 0.1rem;">{{ '可充电容量(MWh)' }}</span>{{ rechargeCap }}</div>
            <div class="value">
              <span style="font-size: 0.12rem;margin-right: 0.1rem;">{{'可放电容量(MWh)' }}</span>
              <span style="font-size: 0.22rem;">{{ dischargeCap }}</span></div>
          </div>
        </div>
      </div>
      <div class="centerBox">
        <div class="c_L_box">
          <div class="p_bgcBox">
            <div class="p_bgc">
              <img src="../../assets/detail/sbgc13.png">
              <div class="title">{{ '并网电压' }}</div>
              <div class="value">{{ gridVoltageOnConnection + 'kv' }}</div>
            </div>
            <div class="p_bgc">
              <img src="../../assets/detail/sbgc14.png">
              <div class="title">{{ '总充电量' }}</div>
              <div class="value">{{ totalChargingAmount + 'MWh' }}</div>
            </div>
          </div>
          <div class="p_bgcBox">
            <div class="p_bgc">
              <img src="../../assets/detail/sbgc15.png">
              <div class="title">{{ '储能容量' }}</div>
<!--              <div class="value" style="bottom: 12%;">-->
              <div class="value" style="bottom: 24%;">
                <div>{{ storageCapacity + 'kv' }}</div>
<!--                <div>{{ '10' + 'kv' }}</div>-->
              </div>
            </div>
            <div class="p_bgc">
              <img src="../../assets/detail/sbgc12.png">
              <div class="title">{{ '总放电量' }}</div>
              <div class="value">{{ totalDischargingAmount + 'MWh' }}</div>
            </div>
          </div>
        </div>
        <div class="c_C_box">
          <div class="c_c_L">
            <div class="c_chart_box">
              <div id="chart4" style="height: 86%;width: 100%"></div>
              <div style="text-align: center;font-size: 0.12rem;">{{ '储能SOC' }}</div>
            </div>
            <div class="c_t_box">
              <div class="title_bgc">{{ '告警' }}</div>
              <div class="cont_box">
                <div>{{ '电池预制舱告警' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '监测终端告警' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '通讯链路告警' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
            </div>
          </div>
          <div class="c_c_C">
            <div class="c_chart_box">
              <div id="chart5" style="height: 86%;width: 100%"></div>
              <div style="text-align: center;font-size: 0.12rem;">{{ '储能SOH' }}</div>
            </div>
            <div class="c_t_box">
              <div class="title_bgc">{{ '消防信息' }}</div>
              <div class="cont_box">
                <div>{{ '电池舱烟感告警' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '消防信号火灾告警' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '气体灭火动作' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '电池预制舱消防系统' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '就地控制' }}</div>
                <div>{{ '2' + '次' }}</div>
              </div>
            </div>
          </div>
          <div class="c_c_R">
            <div class="c_chart_box">
              <div id="chart6" style="height: 86%;width: 100%"></div>
              <div style="text-align: center;font-size: 0.12rem;">{{ '电站综合运行效率' }}</div>
            </div>
            <div class="c_t_box">
              <div class="title_bgc">{{ '环境监测' }}</div>
              <div class="cont_box">
                <div>{{ '空调停运状态' }}</div>
                <div>{{ '正常' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '环境温度' }}</div>
                <div>{{ '36' + '℃' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '环境湿度' }}</div>
                <div>{{ '12' + 'RH' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '有害气体检测' }}</div>
                <div>{{ '无' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '门禁告警' }}</div>
                <div>{{ '3' + '次' }}</div>
              </div>
              <div class="cont_box">
                <div>{{ '风机异常告警' }}</div>
                <div>{{ '0' + '次' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="c_R_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '系统运行功率' }}</div>
          </div>
          <div style="width: 100%;height: 86%;">
            <div id="chart3" style="height: 100%;width: 100%;"></div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="b_L_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '系统日充电量' }}</div>
          </div>
          <div style="width: 100%;height: 86%;">
            <div id="chart1" style="height: 100%;width: 100%;"></div>
          </div>
        </div>
        <div class="b_R_box">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '系统日放电量' }}</div>
          </div>
          <div style="width: 100%;height: 86%;">
            <div id="chart2" style="height: 100%;width: 100%;"></div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter></HomeFooter>
  </div>
</template>
<script>
import myHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/footer.vue";
import * as echarts from "echarts";
import {storage} from "@/api/storage";

export default {
  name: "storage",
  components: {HomeFooter, myHeader},
  data() {
    return {
      stationId: '',
      dayChargeQty: '',
      dayDischargeQty: '',
      dayEarnings: '',
      dischargeCap: '',
      rechargeCap: '',
      safeDays: '',
      stationRunTime: '',
      gridVoltageOnConnection: '',
      storageCapacity: '',
      totalChargingAmount: '',
      totalDischargingAmount: '',
    }
  },
  created() {
    this.stationId = sessionStorage.getItem('stationId')
    this.getStorageBasic()
    this.getStoragePowerConsumption()
    this.getStorageSystemDailyChargeCapacity()
  },
  mounted() {
    this.getChart4()
    this.getChart5()
    this.getChart6()
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getStorageBasic() {
      const param = {
        id: this.stationId,
      }
      storage.storageBasic(param).then(res => {
        if (res.code == '200') {
          this.dayChargeQty = res.data.dayChargeQty
          this.dayDischargeQty = res.data.dayDischargeQty
          this.dayEarnings = res.data.dayEarnings
          this.dischargeCap = res.data.dischargeCap
          this.rechargeCap = res.data.rechargeCap
          this.safeDays = res.data.safeDays
          this.stationRunTime = res.data.stationRunTime
        }
      })
    },
    getStoragePowerConsumption(){
      const param = {
        id: this.stationId
      }
      storage.storagePowerConsumption(param).then(res => {
        if (res.code == '200') {
          this.gridVoltageOnConnection = res.data.gridVoltageOnConnection
          this.storageCapacity = res.data.storageCapacity
          this.totalChargingAmount = res.data.totalChargingAmount
          this.totalDischargingAmount = res.data.totalDischargingAmount
        }
      })
    },
    getStorageSystemDailyChargeCapacity(){
      const param = {
        id: this.stationId
      }
      storage.storageSystemDailyChargeCapacity(param).then(res => {
        if (res.code == '200') {
          this.getChart1(res.data.chargingAmount,res.data.hourPeakValley)
          this.getChart2(res.data.dischargingAmount,res.data.hourPeakValley)
          this.getChart3(res.data.chargePower,res.data.dischargePower,res.data.hourPeakValley)
        }
      })
    },
    getChart1(chargingAmount,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart1'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '电量: MWh    {j|} 尖  {f|} 峰  {p|} 平  {g|} 谷',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
            rich: {
              'j': {
                backgroundColor: 'rgba(225,66,66,.3)',
                width: 20
              },
              'f': {
                backgroundColor: 'rgba(225,199,75,.3)',
                width: 20
              },
              'p': {
                backgroundColor: 'rgba(61,226,188,.3)',
                width: 20
              },
              'g': {
                backgroundColor: 'rgba(58,109,212,.3)',
                width: 20
              }
            }
          }
        },
        legend: {
          show: true,
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '0',  // 左边距
          right: '0', // 右边距
          bottom: '0', // 底部距离
          top: '15%', // 顶部距离
          containLabel: true // 包含坐标轴的标签
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white',
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '今日充电量',
            data: chargingAmount,
            type: 'bar',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                  [
                    {offset: 0, color: '#3de2bc'},   // 0% 处的颜色
                    {offset: 1, color: '#18a887'}   // 100% 处的颜色
                  ]
              )
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart2(dischargingAmount,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart2'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '电量: MWh    {j|} 尖  {f|} 峰  {p|} 平  {g|} 谷',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
            rich: {
              'j': {
                backgroundColor: 'rgba(225,66,66,.3)',
                width: 20
              },
              'f': {
                backgroundColor: 'rgba(225,199,75,.3)',
                width: 20
              },
              'p': {
                backgroundColor: 'rgba(61,226,188,.3)',
                width: 20
              },
              'g': {
                backgroundColor: 'rgba(58,109,212,.3)',
                width: 20
              }
            }
          }
        },
        legend: {
          show: true,
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '0',  // 左边距
          right: '0', // 右边距
          bottom: '0', // 底部距离
          top: '15%', // 顶部距离
          containLabel: true // 包含坐标轴的标签
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white',
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '今日放电量',
            data: dischargingAmount,
            type: 'bar',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                  [
                    {offset: 0, color: '#ffc74b'},   // 0% 处的颜色
                    {offset: 1, color: '#ffc74b'}   // 100% 处的颜色
                  ]
              )
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart3(chargePower,dischargePower,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart3'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '电量: MWh    {j|} 尖  {f|} 峰  {p|} 平  {g|} 谷',
          textStyle: {
            color: '#ffffff',
            fontSize: '12',
            rich: {
              'j': {
                backgroundColor: 'rgba(225,66,66,.3)',
                width: 20
              },
              'f': {
                backgroundColor: 'rgba(225,199,75,.3)',
                width: 20
              },
              'p': {
                backgroundColor: 'rgba(61,226,188,.3)',
                width: 20
              },
              'g': {
                backgroundColor: 'rgba(58,109,212,.3)',
                width: 20
              }
            }
          }
        },
        legend: {
          show: true,
          top: '0',
          right: '4%',
          textStyle: {
            color: '#ffffff'
          },
        },
        grid: {
          left: '0',  // 左边距
          right: '0', // 右边距
          bottom: '0', // 底部距离
          top: '15%', // 顶部距离
          containLabel: true // 包含坐标轴的标签
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white',
            formatter: (value) => {
              if (value < 0) return -value
              else return value
            }
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '充电功率',
            data: chargePower,
            type: 'bar',
            stack: 'Total',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                  [
                    {offset: 0, color: '#3de2bc'},   // 0% 处的颜色
                    {offset: 1, color: '#18a887'}   // 100% 处的颜色
                  ]
              )
            }
          },
          {
            name: '放电功率',
            data: dischargePower,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 这四个参数分别表示渐变的起点 (x1, y1) 与终点 (x2, y2)
                  [
                    {offset: 0, color: '#24418f'},   // 0% 处的颜色
                    {offset: 1, color: '#124eb3'}   // 100% 处的颜色
                  ]
              )
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart4() {
      var myChart = echarts.init(document.getElementById('chart4'), 'shine');
      var option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderWidth: 1
            },
            data: [
              {
                name: '储能',
                value: 75,
                itemStyle: {
                  color: '#318dee',
                },
                label: {
                  show: true,  //单独显示该数据项
                  formatter: '{d}%',
                  fontSize: '18',
                  color: '#fff'
                }
              },
              {
                value: 25,
                itemStyle: {
                  color: '#09234f',
                },
                label: {
                  emphasis: {
                    show: false
                  }
                }
              }
            ],
            label: {
              normal: {//默认不显示数据
                show: false,
                position: 'center',
              },
              color: '#fff',
            },
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart5() {
      var myChart = echarts.init(document.getElementById('chart5'), 'shine');
      var option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderWidth: 1
            },
            data: [
              {
                name: '储能',
                value: 75,
                itemStyle: {
                  color: '#2ee1b2',
                },
                label: {
                  show: true,  //单独显示该数据项
                  formatter: '{d}%',
                  fontSize: '18',
                  color: '#fff'
                }
              },
              {
                value: 25,
                itemStyle: {
                  color: '#09234f',
                },
                label: {
                  emphasis: {
                    show: false
                  }
                }
              }
            ],
            label: {
              normal: {//默认不显示数据
                show: false,
                position: 'center',
              },
              color: '#fff',
            },
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart6() {
      var myChart = echarts.init(document.getElementById('chart6'), 'shine');
      var option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderWidth: 1
            },
            data: [
              {
                name: '储能',
                value: 75,
                itemStyle: {
                  color: '#ffc74b',
                },
                label: {
                  show: true,  //单独显示该数据项
                  formatter: '{d}%',
                  fontSize: '18',
                  color: '#fff'
                }
              },
              {
                value: 25,
                itemStyle: {
                  color: '#09234f',
                },
                label: {
                  emphasis: {
                    show: false
                  }
                }
              }
            ],
            label: {
              normal: {//默认不显示数据
                show: false,
                position: 'center',
              },
              color: '#fff',
            },
          }
        ]
      };
      myChart.setOption(option, true);
    },
  }
}
</script>
<style scoped>
.back_nav {
  width: 0.8rem;
  height: 0.34rem;
  background: url('../../assets/images/monitoring/back_nav.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.backBox {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleLeft {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#storage {
  height: 100vh;
  background-color: #071024;
  font-size: 0.16rem;
  box-sizing: border-box;
}

.bigBox {
  padding: 0 0.2rem 0 0.2rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #071024;
}

.bigBox .topBox {
  box-sizing: border-box;
  height: 1rem;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .topBox .t_bgc {
  flex: 16.2%;
  max-width: 16.2%;
  height: 100%;
  background: url(../../assets/detail/bgc2.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.bigBox .topBox .t_bgc .imgBox {
  flex: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigBox .topBox .t_bgc .t_bgc_labelBox {
  height: 60%;
  flex: 70%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.bigBox .topBox .t_bgc .t_bgc_labelBox .label {
  font-size: 0.22rem;
}

.bigBox .topBox .t_bgc .t_bgc_labelBox .value {
  font-size: 0.14rem;
}

.bigBox .centerBox {
  margin-top: 0.1rem;
  box-sizing: border-box;
  height: 3.6rem;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_L_box {
  box-sizing: border-box;
  padding: 0.6rem 0.2rem 0.6rem 0.2rem;
  flex: 33%;
  max-width: 33%;
  height: 100%;
  background: url(../../assets/detail/bgc6.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.bigBox .centerBox .c_L_box .p_bgcBox {
  flex: 40%;
  max-height: 40%;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_L_box .p_bgcBox .p_bgc {
  position: relative;
}

.bigBox .centerBox .c_L_box .p_bgcBox .p_bgc .title {
  position: absolute;
  top: 0.04rem;
  left: 1.16rem;
  font-size: 0.14rem;
}

.bigBox .centerBox .c_L_box .p_bgcBox .p_bgc .value {
  width: 100%;
  position: absolute;
  bottom: 24%;
  text-align: right;
  box-sizing: border-box;
  padding-right: 0.1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.bigBox .centerBox .c_C_box {
  box-sizing: border-box;
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  flex: 33%;
  max-width: 33%;
  height: 100%;
  background: url(../../assets/detail/bgc6.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_C_box .c_c_L {
  flex: 33%;
  max-width: 33%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.c_chart_box {
  flex: 49.5%;
  max-height: 49.5%;
  width: 100%;
}

.c_t_box {
  box-sizing: border-box;
  padding: 0.06rem 0.14rem 0;
  width: 100%;
  max-height: 49.5%;
  flex: 49.5%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 0.01rem solid #122348;
}

.c_t_box .title_bgc {
  margin-bottom: 0.1rem;
  width: 100%;
  flex: 20%;
  max-height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/detail/sbgc11.png) no-repeat;
  background-size: 100% 100%;
  font-size: 0.14rem;
}

.c_t_box .cont_box {
  margin-bottom: 0.05rem;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
}

.bigBox .centerBox .c_C_box .c_c_C {
  flex: 33%;
  max-width: 33%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_C_box .c_c_R {
  flex: 33%;
  max-width: 33%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.bigBox .centerBox .c_C_box .c_c_R .c_t_box {
  border: none;
}

.bigBox .centerBox .c_R_box {
  box-sizing: border-box;
  padding: 0 0.2rem 0.2rem 0.2rem;
  flex: 33%;
  max-width: 33%;
  height: 100%;
  background: url(../../assets/detail/bgc6.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .bottomBox {
  margin-top: 0.1rem;
  box-sizing: border-box;
  height: 3.74rem;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.bigBox .bottomBox .b_L_box {
  box-sizing: border-box;
  padding: 0 0.2rem 0.2rem 0.2rem;
  flex: 49.8%;
  max-width: 49.8%;
  height: 100%;
  background: url(../../assets/detail/bgc7.png) no-repeat;
  background-size: 100% 100%;
}

.bigBox .bottomBox .b_R_box {
  box-sizing: border-box;
  padding: 0 0.2rem 0.2rem 0.2rem;
  flex: 49.8%;
  max-width: 49.8%;
  height: 100%;
  background: url(../../assets/detail/bgc7.png) no-repeat;
  background-size: 100% 100%;
}

.sTitleBox {
  height: 14%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.16rem;
}
</style>
